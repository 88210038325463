











import { Component, Vue } from 'vue-property-decorator';
import DemoForm from '@/modules/marketing/forms/DemoForm.vue';
@Component({
  name: 'demo',
  layout: 'default',
  metaInfo: {
    title: 'Request Demos',
  },
  components: {
    DemoForm,
  },
})

export default class Demo extends Vue {}
