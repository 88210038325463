
import { Component, Vue } from 'vue-property-decorator';

declare global {
  interface Window {
    hbspt: any;
  }
}

@Component({
  components: {},
})
export default class DemoForm extends Vue {
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);
    // script.addEventListener('load', () => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '9184409',
        formId: '3534dcd8-e3c9-4143-afd3-be4cafc9f4ea',
        target: '#hubspotForm',
      });
    }
  }
}
